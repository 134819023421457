import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { Status } from '../member-slice.models';
import { MemberCommentsState } from './member-comments-slice.models';
import {
  fetchInitialMemberComments,
  fetchMemberComments,
  deleteMemberComment,
} from './member-comments-slice.actions';
import { PAGE_SIZE } from '@common/constants/page-size';

export const MEMBER_COMMENTS_SLICE_NAME = 'memberComments';

const initialState: MemberCommentsState = {
  status: Status.INITIAL,
};

const buildFetchInitialMemberComments = (
  builder: ActionReducerMapBuilder<MemberCommentsState>,
) => {
  builder.addCase(
    fetchInitialMemberComments.fulfilled,
    (state, { payload }): MemberCommentsState => {
      if (state.status === Status.INITIAL) {
        return {
          status: Status.READY,
          user: payload.user,
          comments: payload.comments.map((c) => ({ ...c, status: 'READY' })),
          pagination: payload.pagination,
        };
      }

      return state;
    },
  );
  builder.addCase(
    fetchInitialMemberComments.rejected,
    (state): MemberCommentsState => ({
      ...state,
      status: Status.ERROR,
    }),
  );
};

const buildFetchMemberComments = (
  builder: ActionReducerMapBuilder<MemberCommentsState>,
) => {
  builder.addCase(fetchMemberComments.pending, (state): MemberCommentsState => {
    if (state.status === Status.READY) {
      return {
        ...state,
        status: Status.PENDING,
      };
    }

    return state;
  });
  builder.addCase(
    fetchMemberComments.fulfilled,
    (state, { payload }): MemberCommentsState => {
      if (state.status === Status.PENDING) {
        return {
          ...state,
          status: Status.READY,
          comments: payload.comments.map((c) => ({ ...c, status: 'READY' })),
          pagination: payload.pagination,
        };
      }

      return state;
    },
  );
  builder.addCase(
    fetchMemberComments.rejected,
    (state): MemberCommentsState => ({
      ...state,
      status: Status.ERROR,
    }),
  );
};

const buildDeleteMemberComments = (
  builder: ActionReducerMapBuilder<MemberCommentsState>,
) => {
  builder.addCase(
    deleteMemberComment.pending,
    (state, action): MemberCommentsState => {
      if (state.status === Status.READY) {
        return {
          ...state,
          comments: state.comments.map((c) =>
            c.id === action.meta.arg.id ? { ...c, status: 'PENDING' } : c,
          ),
        };
      }

      return state;
    },
  );
  builder.addCase(
    deleteMemberComment.fulfilled,
    (state, action): MemberCommentsState => {
      if (state.status === Status.READY) {
        const newItemCount = state.pagination.itemCount - 1;
        return {
          ...state,
          comments: [
            ...state.comments.filter((c) => c.id !== action.meta.arg.id),
            ...(action.payload
              ? [{ status: 'READY' as const, ...action.payload }]
              : []),
          ],
          pagination: {
            ...state.pagination,
            itemCount: state.pagination.itemCount - 1,
            pageCount: Math.ceil(newItemCount / PAGE_SIZE),
          },
          status: Status.READY,
        };
      }

      return state;
    },
  );
  builder.addCase(
    deleteMemberComment.rejected,
    (state, action): MemberCommentsState => {
      if (state.status === Status.READY) {
        // TODO: we should handle error while deleting comment
        return {
          ...state,
          comments: state.comments.map((c) =>
            c.id === action.meta.arg.id ? { ...c, status: 'READY' } : c,
          ),
        };
      }

      return state;
    },
  );
};

const memberPostsSlice = createSlice({
  name: MEMBER_COMMENTS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildFetchInitialMemberComments(builder);
    buildFetchMemberComments(builder);
    buildDeleteMemberComments(builder);
  },
});

export const reducer = memberPostsSlice.reducer;

import { IHttpClient } from '@wix/yoshi-flow-editor';

export type TranslationsService = ReturnType<typeof createTranslationsService>;

interface TranslationsData {
  [key: string]: string;
}

const FALLBACK_TRANSLATIONS = {
  fullDate: '{time, date, wcFullDate}',
  'post-list-item.post-in-category': 'In {category}',
  'share-link.copy': 'Copy Link',
  'post-actions.delete-post': 'Delete Post',
  'comment-actions.delete-comment': 'Delete Comment',
  'comment-marked-type.best-answer': 'Best Answer',
  'delete-confirmation.cancel': 'Cancel',
  'delete-confirmation.delete': 'Delete',
  'no-posts.title': 'I Have No Forum Posts Yet',
  'no-posts.content': 'Check back soon.',
  'no-comments.title': 'I Have No Forum Comments Yet',
  'no-comments.content': 'Check back soon.',
  'profile-forum-posts.forum-posts': 'Forum Posts',
  'profile-forum-comments.forum-comments': 'Forum Comments',
  'share-link.link-copied': 'Link copied to clipboard.',
  'share-link.link-copied-failure': 'Failed to copy link to clipboard.',
  'broken-state.title': 'Something Isn’t Working…',
  'broken-state.btn-text': 'Refresh Page',
  'broken-state.suggestions-title':
    'It looks like there was a technical problem. Here’s what you can do:',
  'broken-state.suggestion-1': 'Refresh the page.',
  'broken-state.suggestion-2': 'Check your internet connection.',
  'broken-state.suggestion-3': 'Open the site in a different browser.',
  'broken-state.no-forum.title': 'This page can not be rendered.',
  'broken-state.no-forum.description': 'Please contact the site owner.',
  'delete-post-modal.delete-post': 'Delete Post',
  'delete-post-modal.will-detele-comments':
    'Deleting this post will delete its comments too.',
  'delete-comment-modal.delete-comment': 'Delete Comment',
  'delete-comment-modal.are-you-sure':
    'Are you sure you want to delete this comment?',
};

export const createTranslationsService = (
  {
    httpClient,
  }: {
    httpClient: IHttpClient;
  },
  config: { baseUrl: string; translationsName: string },
) => {
  const getTranslations = async (
    language: string,
  ): Promise<TranslationsData> => {
    const baseUrl = config.baseUrl;
    const translationsName = config.translationsName;

    if (!baseUrl || !translationsName) {
      return FALLBACK_TRANSLATIONS;
    }

    const translationsUrl =
      baseUrl + translationsName + '_' + language + '.json';

    return httpClient
      .get<TranslationsData>(translationsUrl)
      .then(({ data }) => data);
  };

  return {
    _name_: 'translationsService' as const,
    getTranslations,
  };
};

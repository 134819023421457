import { IWixAPI } from '@wix/yoshi-flow-editor';
import { IFedOpsLogger } from '@wix/native-components-infra/dist/src/types/types';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import { ServicesMap } from '@common/services';
import {
  Status,
  getMemberComments,
  CommentType,
} from '@common/store/member-slice/member-comments-slice';
import { initErrorMonitorMiddleware } from '@common/store/errors-slice';
import { RootState, rootReducer, rootActions } from './root-reducer';

export type ThunkExtra = { services: ServicesMap };
export type AppDispatch = ThunkDispatch<RootState, ThunkExtra, Action>;
export type RootStore = ReturnType<typeof initStore>;

enum Interactions {
  InitComments = 'init-comments',
  DeleteComment = 'delete-comment',
}

const MEMBER_COMMENTS_ORIGIN = 'member_comments_page';

export const initStore = (
  services: ServicesMap,
  errorLogger: (error: Error) => void,
) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { services },
        },
        serializableCheck: false,
      }).concat([initErrorMonitorMiddleware(errorLogger)]),
  });

  return store;
};

export const initActions = (
  wixCodeApi: IWixAPI,
  store: RootStore,
  services: ServicesMap,
  errorLogger: (error: Error) => void,
  biLogger: (biEvent: { evid: number; [key: string]: any }) => void,
  fedopsLogger: IFedOpsLogger,
) => {
  const dispatch: AppDispatch = store.dispatch;

  return {
    // @TODO maybe group loggers into app actions?
    logError: errorLogger,
    logBiEvent: biLogger,
    navigateTo: (
      path: string,
      queryParams: { [key: string]: any } = {
        origin: MEMBER_COMMENTS_ORIGIN,
      },
    ) => {
      if (wixCodeApi.location.to) {
        const url = new URL(path);

        if (queryParams) {
          for (const key in queryParams) {
            url.searchParams.append(key, queryParams[key]);
          }
        }

        wixCodeApi.location.to(url.toString());
      }
    },
    navigateToComment: async (
      comment: CommentType,
      queryParams: { [key: string]: any } = {
        origin: MEMBER_COMMENTS_ORIGIN,
      },
    ) => {
      if (!wixCodeApi.location.to) {
        return;
      }

      const url = new URL(comment.url);

      if (queryParams) {
        for (const key in queryParams) {
          url.searchParams.append(key, queryParams[key]);
        }
      }

      wixCodeApi.location.to(url.toString());
    },
    // App actions
    initAppBase: async ({ language }: { language: string }) => {
      const baseProps = {
        language,
        isMobile: wixCodeApi.window.formFactor === 'Mobile',
      };

      await Promise.all([
        dispatch(rootActions.setBaseProps(baseProps)),
        dispatch(rootActions.fetchCurrentUser()),
        dispatch(rootActions.fetchTranslations(language)),
      ]);
    },
    initAppData: async ({ viewedUserId }: { viewedUserId: string }) => {
      fedopsLogger.interactionStarted(Interactions.InitComments);

      await dispatch(
        rootActions.fetchInitialMemberComments({ userId: viewedUserId }),
      );

      fedopsLogger.interactionEnded(Interactions.InitComments);
    },
    fetchCurrentUser: async () => {
      await dispatch(rootActions.fetchCurrentUser());
    },
    fetchMemberComments: async (options: {
      sort?: string;
      offset?: number;
    }) => {
      await dispatch(rootActions.fetchMemberComments(options));
    },
    deleteMemberComment: async (
      comment: CommentType,
      isLastItemOnPage: boolean,
    ) => {
      fedopsLogger.interactionStarted(Interactions.DeleteComment);

      const memberComments = getMemberComments(store.getState());

      if (memberComments.status === Status.READY) {
        const {
          pagination: { currentFilter },
        } = memberComments;
        let updatedFilter = { ...currentFilter };

        await dispatch(rootActions.deleteMemberComment(comment));

        if (isLastItemOnPage) {
          const updatedOffset = currentFilter.offset - currentFilter.size;

          updatedFilter = {
            ...updatedFilter,
            offset: updatedOffset >= 0 ? updatedOffset : currentFilter.offset,
          };

          await dispatch(rootActions.fetchMemberComments(updatedFilter));
        }

        fedopsLogger.interactionEnded(Interactions.DeleteComment);
      }
    },
  };
};
